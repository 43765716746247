<template>
    <div v-loading.fullscreen.lock="loading" class="content mra-login">
        <the-header></the-header>
        <div class="content-area" style="padding-top:20px;">
            <div class="container-fluid">
                <h5 class="mb-5 text-center">
                    If you are a current member of the MRA, you will receive special pricing by clicking the box next to the “Already a member of the MRA” and enter your member ID to receive your discount. After agreeing to the Service Activation Agreement and paying for the course(s), you can continue directly to login to the site. If you have any questions or don’t see a course you need, please contact us at <a :href="'mailto:' + infoEmail" class="linkColor">{{ infoEmail }}</a>.
                </h5>
                <div class="row">
                    <div class="col-md-12 col-lg-12 col-xl-5 form-section responsive-order-1">
                        <validation-observer ref="formValidator" v-slot="{ handleSubmit }" class="custom-bg-mobil">
                            <form role="form" @submit.prevent="handleSubmit()">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h4 class="" style="color: #444c57">Your Details</h4>
                                        <hr/>
                                    </div>
                                    <br/>
                                </div>
                                <div class="form-row">
                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <label class="form-control-label">Company Name </label>
                                        <base-input v-model="employee.company_name" name="Company name" placeholder="Company Name" type="text"/>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <label class="form-control-label"
                                        >First Name <span class="req"> *</span></label
                                        >
                                        <base-input v-model="employee.first_name" name="First name" placeholder="First Name" rules="required" type="text"/>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <label class="form-control-label">Last Name <span class="req"> *</span></label>
                                        <base-input v-model="employee.last_name" name="Last name" placeholder="Last Name" rules="required" type="text"/>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <label class="form-control-label">Email <span class="req"> *</span></label>
                                        <base-input v-model="employee.email" name="Email" placeholder="Email" rules="required" type="email"/>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <label class="form-control-label">Phone <span class="req"> *</span></label>
                                        <base-input v-model="employee.phone" name="Telephone" placeholder="Phone" rules="required" @input="acceptNumber"/>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <label class="form-control-label">Address <span class="req"> *</span></label>
                                        <base-input v-model="employee.address" name="Address" placeholder="Address" type="text"/>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <label class="form-control-label">City <span class="req"> *</span></label>
                                        <base-input v-model="employee.city" name="City" placeholder="City" type="text"/>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <label class="form-control-label">State <span class="req"> *</span></label>
                                        <base-input v-model="employee.state" name="State" placeholder="State" type="text"/>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <label class="form-control-label">Zip Code <span class="req"> *</span></label>
                                        <base-input v-model="employee.zipcode" name="Zip code" placeholder="Zip Code" type="number"/>
                                    </div>
                                </div>
                                <hr/>
                                <div v-if="lead_id" class="row">
                                    <h3 class="mt-2 ml-2" style="color: #444c57">
                                        Login Information
                                    </h3>
                                </div>
                                <hr v-if="lead_id"/>
                                <div class="form-row">
                                    <div class="col-md-6">
                                        <label class="form-control-label">Username <span class="req"> *</span></label>
                                        <base-input v-model="employee.email" name="Username" placeholder="Username" readonly type="test"/>
                                    </div>
                                    <div class="col-md-6">
                                        <label class="form-control-label">Password <span class="req"> *</span></label>
                                        <base-input v-model="employee.password" :type="passwordFieldType" name="Password" placeholder="Password"/>
                                        <div class="password-eye passwordview">
                                            <span @click.prevent="switchVisibility">
                                                <i class="fa fa-eye" title="Show Password"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </validation-observer>
                    </div>
                    <div class="col-md-12 col-lg-12 col-xl-7 col-12 responsive-order">
                        <div class="row">
                            <div class="col-md-12 mt-0 mb-3 bg-gray-100 p-2 shadow-sm">
                                <h5 class="mb-0 new-signup">
                                    Not a member, <a class="linkColor" href="https://www.themassrest.org/why-join-the-mra.html">click here</a> <span>to join.</span>
                                </h5>
                            </div>
                            <div class="col-md-12">
                                <base-checkbox v-model="already_member">
                                    <b>Already a member of the MRA? (Enter your Member ID to receive a discount).</b>
                                </base-checkbox>
                            </div>

<!--                            <div v-if="already_member" class="col-md-12 mt-1">-->
<!--                                <label class="form-control-label">Member ID:</label>-->
<!--                                <input id="memberId" v-model="member_id" :style="inputStyle" class="form-control" placeholder="Provide member ID" type="text"/>-->
<!--                            </div>-->
                            <div class="col-md-12 mt-2">
                                <div class="container courseBox_responsive">
                                    <div class="row priceGrid  custom-bg responsiveScrollBar" style="font-weight:bolder;">
                                        <div class="col-lg-6 col-md-6 col-sm-8 col-8 price-info">Courses</div>
                                        <div class="col-lg-3 col-md-3 col-sm-2 col-2 price-info text-center">Member Pricing</div>
                                        <div class="col-lg-3 col-md-3 col-sm-2 col-2 price-info text-center">Non-Member Pricing</div>
                                    </div>
                                    <el-checkbox-group v-model="checked_coursefolders">
                                    <span v-for="option in coursefolders" :key="option.id" class="odd-row">
                                        <div class="row priceGrid responsiveScrollBar">
                                            <div class="col-lg-6 col-md-6 col-sm-8 col-8">
                                                <el-popover :id="option.id" :key="option.id" :ref="`fromPopOver${option.id}`" placement="top-start" trigger="hover" width="500">
                                                    <div class="provideScroller">
                                                        <ul>
                                                            <li v-for="(courses, index) in option.attachedCourses" :key="index">{{ courses.name }}</li>
                                                        </ul>
                                                    </div>
                                                </el-popover>
                                                <el-checkbox :label="option.id" @change="courseFolderChecked(option.id)">
                                                    <span>{{ option.coursefolder_name }}</span>
                                                </el-checkbox>
                                                <span v-popover="`fromPopOver${option.id}`" class="text-primary knowMore-info">Learn More</span>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-sm-2 col-2 text-center">
                                                <span class="price-info ">${{ option.member_price }}</span>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-sm-2 col-2 text-center">
                                                <span class="price-info">${{ option.non_member_price }}</span>
                                            </div>
                                            <br/>
                                        </div>
                                    </span>
                                    </el-checkbox-group>
                                    <el-checkbox-group v-model="checked_courses">
                                    <span v-for="option in course" :key="option.id" class="odd-row">
                                        <div class="row priceGrid responsiveScrollBar">
                                            <div class="col-lg-6 col-md-6 col-sm-8 col-8">
                                                <el-checkbox v-if="option.course_type == 0" :label="option.id" @change="coursesFolder(option.id)">{{ option.course_name }}</el-checkbox>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-sm-2 col-2 text-center">
                                                <span class="price-info">${{ option.member_price }}</span>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-sm-2 col-2 text-center">
                                                <span class="price-info">${{ option.non_member_price }}</span>
                                            </div>
                                        </div>
                                    <br/>
                                    </span>
                                    </el-checkbox-group>
                                    <el-checkbox-group v-model="checked_other_courses">
                                    <span v-for="option in basicPackage1" :key="option.id" class="odd-row">
                                        <div class="row priceGrid responsiveScrollBar">
                                            <div class="col-lg-6 col-md-6 col-sm-8 col-8">
                                            <el-popover :id="option.id" :key="option.id" :ref="`fromPopOver${option.id}`" placement="top-start" trigger="hover" width="500">
                                                <div class="provideScroller">
                                                    <ul>
                                                        <li v-for="(courses, index) in option.attachedCourses" :key="index">{{ courses.name }}</li>
                                                    </ul>
                                                </div>
                                            </el-popover>
                                            <el-checkbox :label="option.id">{{ option.coursefolder_name }}</el-checkbox>
                                                <span v-popover="`fromPopOver${option.id}`" class="text-primary knowMore-info">Learn More</span>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-sm-2 col-2 text-center">
                                                <span class="price-info">${{ option.member_price }}</span>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-sm-2 col-2 text-center">
                                                <span class="price-info">${{ option.non_member_price }}</span>
                                            </div>
                                        </div>
                                    </span>
                                    </el-checkbox-group>
                                    <el-checkbox-group v-model="checked_other_courses">
                                    <span v-for="option in basicPackage2" :key="option.id" class="odd-row">
                                        <div class="row priceGrid responsiveScrollBar">
                                            <div class="col-lg-6 col-md-6 col-sm-8 col-8">
                                                <el-popover :id="option.id" :key="option.id" :ref="`fromPopOver${option.id}`" placement="top-start" trigger="hover" width="500">
                                                    <div class="provideScroller">
                                                        <ul>
                                                            <li v-for="(courses, index) in option.attachedCourses" :key="index">{{ courses.name }}</li>
                                                        </ul>
                                                    </div>
                                                </el-popover>
                                                <el-checkbox v-if="checked_coursefolders.length > 0" :label="option.id" disabled>{{ option.coursefolder_name }}</el-checkbox>
                                                <el-checkbox v-else-if="checked_coursefolders.length == 0" :label="option.id">{{ option.coursefolder_name }}</el-checkbox>
                                                <span v-popover="`fromPopOver${option.id}`" class="text-primary knowMore-info">Learn More</span>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-sm-2 col-2 text-center">
                                                <span class="price-info">${{ option.member_price }}</span>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-sm-2 col-2 text-center">
                                                <span class="price-info">${{ option.non_member_price }}</span>
                                            </div>
                                        </div>
                                        <br/>
                                    </span>
                                    </el-checkbox-group>
                                </div>
                            </div>
                            <br/>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div v-if="(checked_courses.length || checked_coursefolders.length || checked_other_courses.length) && !lead_id" class="text-center pb-3">
                            <base-button class="mt-2" @click.prevent="checkIfMRAMember">Sign Up</base-button>
                        </div>
                        <div v-else class="text-center py-3">
                            <base-button v-if="!showPricePlan" class="basebutton mt-2" disabled>Sign Up</base-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal :show.sync="agreementModal" class="user-modal">
            <h3 slot="header">Terms and Conditions</h3>
            <form>
                <div class="agreement-content">
                    <agreement type="individual"></agreement>
                </div>
                <div class="text-center mt-2">
                    <base-button type="success" @click.prevent="finalCreateAccount">
                        I Agree
                    </base-button>
                    <base-button type="danger" @click.prevent="cancelAgreement">
                        Cancel
                    </base-button>
                </div>
            </form>
        </modal>
        <modal :show.sync="showPaymentOption">
            <h4 slot="header" class="modal-title mb-0" style="color: #444c57">Payment</h4>
            <mra-credit-card v-if="showPaymentOption" :address="employee.address" :city="employee.city" :is-mra-signup="true" :monthlyAmount="sub_total" :onlySexualCourse="0" :state="employee.state" :yearlyAmount="perYearCost" :zip="employee.zipcode" type="individual" v-on:payClicked="payClicked" :isMraRegister="true"/>
        </modal>
        <the-footer/>
    </div>
</template>
<script>
import {Checkbox, CheckboxGroup, Option, OptionGroup, Select, Table, TableColumn} from "element-ui";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import '@/mra/partials/responsive.css';
import MraCreditCard from "@/mra/MRACreditCard";
import TheHeader from '@/mra/partials/Header.vue';
import TheFooter from '@/mra/partials/Footer.vue';
import Agreement from "@/views/Pages/Agreement.vue";

export default {
    name: "register",
    components: {
        Agreement,
        MraCreditCard,
        [Select.name]: Select,
        [Option.name]: Option,
        [OptionGroup.name]: OptionGroup,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Checkbox.name]: Checkbox,
        [CheckboxGroup.name]: CheckboxGroup,
        TheHeader,
        TheFooter,
    },

    data() {
        return {
            baseUrl: this.$baseUrl,
            agreementModal: false,
            already_member: false,
            member_id: "",
            courseFolderSelectionFocused: false,
            courseSelectionFocused: false,
            showPaymentOption: false,
            search: "",
            otherCourses: [],
            courses: [],
            course: "",
            coursefolders: [],
            checked_courses: [],
            basicPackage1: [],
            basicPackage2: [],
            checked_coursefolders: [],
            checked_other_courses: [],
            formData: {
                employee_first_name: "",
                employee_last_name: "",
                user_type: "",
                employee_address: "",
                employee_city: "",
                employee_state: "",
                employee_zipcode: "",
                employee_email: "",
                employee_phone_num: "",
                password: "",
                address: ""
            },
            employee: {
                company_name: "",
                first_name: "",
                last_name: "",
                emial: "",
                phone: "",
                address: "",
                city: "",
                state: "",
                zipcode: "",
                username: "",
                password: ""
            },
            passwordFieldType: "password",
            showPricePlan: false,
            total_cost: "",
            total_discount: "",
            discountperlocation: "",
            perYearCost: "",
            discount_msg: "",
            lead_id: "",
            siteName: "",
            infoEmail: "",
            inputStyle: "",
            loading: false,
            is_coursefolderSelected: false,
            isCourseSelected: true,
            isSexualHarrasment: 0,
        };
    },
    mounted() {
        this.siteName = 'MRA';
        this.infoEmail = 'mra@train321.com';
        if (this.$route.query.inner) {
            this.$gtag.event("S.H.P. Individual signup", {method: "Google"});
        } else if (this.$route.query.course_type) {
            this.$gtag.event("Sexual Harassment Prevention Individual signup", {
                method: "Google"
            });
        } else {
            this.$gtag.event("Individual signup", {method: "Google"});
        }
    },
    async created() {
        this.loading = true;
        if (this.$route.query.redirection === "yes") {
            this.employee.first_name = localStorage.getItem("fname");
            this.employee.last_name = localStorage.getItem("lname");
            this.employee.email = localStorage.getItem("email");
            this.checked_courses = JSON.parse(localStorage.getItem("courses"));
        } else {
            localStorage.removeItem("fname");
            localStorage.removeItem("lname");
            localStorage.removeItem("email");
            localStorage.removeItem("courses");
        }
        await this.$http.get("mra/discountRules").then(resp => {
            for (let course of resp.data.courses) {
                if (course.id == 100 || course.id == 103 || course.id == 104) {
                    course.course_type = 0;
                }

                if (course.course_type == 0) {
                    let obj = {
                        checked: false,
                        id: course.id,
                        course_name: course.name,
                        member_price: course.member,
                        non_member_price: course.non_member,
                        course_type: course.course_type,
                        cost: course.cost
                    };
                    this.courses.push(obj);
                } else {
                    let obj = {
                        checked: false,
                        id: course.id,
                        course_name: course.name,
                        member_price: course.member,
                        non_member_price: course.non_member,
                        course_type: course.course_type,
                        cost: course.cost
                    };
                    this.otherCourses.push(obj);
                }
            }
            this.course = this.courses;
            for (let coursefolder of resp.data.course_folders) {
                if (coursefolder.id == 5 || coursefolder.id == 4) {
                    coursefolder.folder_type = 1;
                }

                if (coursefolder.folder_type == 1) {
                    let obj1 = {
                        id: coursefolder.id,
                        coursefolder_name: coursefolder.folder_name,
                        member_price: coursefolder.member,
                        non_member_price: coursefolder.non_member,
                        attachedCourses: coursefolder.active_courses
                    };

                    this.coursefolders.push(obj1);
                }
                if (coursefolder.folder_type == 2) {
                    let obj1 = {
                        id: coursefolder.id,
                        coursefolder_name: coursefolder.folder_name,
                        member_price: coursefolder.member,
                        non_member_price: coursefolder.non_member,
                        attachedCourses: coursefolder.active_courses
                    };

                    this.basicPackage1.push(obj1);
                }
                if (coursefolder.folder_type == 3) {
                    let obj1 = {
                        id: coursefolder.id,
                        coursefolder_name: coursefolder.folder_name,
                        member_price: coursefolder.member,
                        non_member_price: coursefolder.non_member,
                        attachedCourses: coursefolder.active_courses
                    };

                    this.basicPackage2.push(obj1);
                }
            }
            this.loading = false;
        });
        if (this.$route.query.course_type) {
            this.isSexualHarrasment = 1;
            this.checkdefaultSexualCourse();
        }
    },
    methods: {
        courseFolderChecked(value) {
            this.is_coursefolderSelected = !this.is_coursefolderSelected;
        },
        coursesFolder(value) {
            this.isCourseSelected = !this.isCourseSelected;
        },
        checkdefaultSexualCourse() {
            // this.courses.forEach(course => {
            this.checked_courses.push(103);
            // })
        },
        finalCreateAccount() {
            this.agreementModal = false;
            this.showPaymentOption = true;
        },
        showDone() {
            this.courseSelectionFocused = true;
        },
        doneClicked() {
            this.courseSelectionFocused = false;
        },
        showDoneButton() {
            this.courseFolderSelectionFocused = true;
        },
        doneButtonClicked(e) {
            this.courseFolderSelectionFocused = false;
        },
        cancelAgreement() {
            this.agreementModal = false;
        },
        switchVisibility() {
            this.passwordFieldType =
                this.passwordFieldType === "password" ? "text" : "password";
        },
        formatPrice(value) {
            return (
                "$ " + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
            );
        },
        acceptNumber() {
            var x = this.employee.phone
                .replace(/\D/g, "")
                .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.employee.phone = !x[2]
                ? x[1]
                : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
        },
        showAgreement() {
            if (this.employee.password == "") {
                return Swal.fire({
                    title: "Error!",
                    text: "Please enter password to continue.",
                    icon: "error"
                });
            }
            this.formData = {
                employee_company_name: this.employee.company_name
                    ? this.employee.company_name
                    : "individual",
                employee_first_name: this.employee.first_name,
                employee_last_name: this.employee.last_name,
                user_type: "individual",
                employee_address: this.employee.address,
                employee_city: this.employee.city,
                employee_state: this.employee.state,
                employee_zipcode: this.employee.zipcode,
                employee_email: this.employee.email,
                employee_phone_num: this.employee.phone,
                password: this.employee.password,
                address: this.employee.address,
                courses: this.checked_courses,
                courseFolders: this.checked_coursefolders,
                otherCourseFolders: this.checked_other_courses,
                already_member: this.already_member,
                member_id: this.member_id,
                i_agree: true
            };
            this.agreementModal = true;
        },
        checkIfMRAMember() {
            if (!this.already_member) {
                Swal.fire({
                    title: "Already a MRA Member?",
                    html: '<a target="__blank" href="https://www.themassrest.org/why-join-the-mra.html"> Click here</a> to join.',
                    showCancelButton: true,
                    confirmButtonClass: "btn btn-success btn-fill",
                    cancelButtonClass: "btn btn-danger btn-fill",
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    buttonsStyling: false,
                    icon: "question"
                }).then(result => {
                    if (result.value) {
                        this.inputStyle = "border:2px solid #f8981c;";
                        this.already_member = true;

                    } else {
                        this.signupClicked();
                    }
                });
            } else {
                this.signupClicked();
            }
        },
        signupClicked() {
            if (
                this.employee.first_name &&
                this.employee.last_name &&
                this.employee.address &&
                this.employee.city &&
                this.employee.state &&
                this.employee.zipcode &&
                this.employee.email
            ) {

                this.$http
                    .post("mra/lead", {
                        company_name: this.employee.company_name
                            ? this.employee.company_name
                            : "individual",
                        first_name: this.employee.first_name,
                        last_name: this.employee.last_name,
                        number_of_locations: 1,
                        number_of_employees: 1,
                        email: this.employee.email,
                        phone_num: this.employee.phone,
                        user_type: "individual",
                        already_member: this.already_member,
                        member_id: this.member_id,
                        course_ids: this.checked_courses,
                        course_folders: this.checked_coursefolders,
                        other_courses: this.checked_other_courses,
                        isSexualHarrasment: this.isSexualHarrasment,
                    })
                    .then(resp => {
                        this.sub_total = resp.data.sub_total;
                        this.showAgreement();
                    });
            } else {
                Swal.fire({
                    title: "Error!",
                    text: "Please fill all mandatory fields.",
                    icon: "error"
                });
            }
        },

        payClicked(cardData) {
            this.loading = true;
            let payment = {
                payment_type: cardData.paymentType,
                cardholder_street_address:
                    cardData.address + "," + cardData.city + "," + cardData.state,
                cardholder_zip: cardData.zip,
                transaction_amount: "",
                token: cardData.token
            };

            if (payment.payment_type == "monthly") {
                payment.transaction_amount = this.sub_total.toFixed(2);
            }
            if (payment.payment_type == "yearly") {
                payment.transaction_amount = this.perYearCost.toFixed(2);
            }
            this.formData.payment = payment;
            this.formData.employee_address = cardData.address;
            this.formData.address = cardData.address;
            this.formData.employee_state = cardData.state;
            this.formData.employee_city = cardData.city;
            this.formData.employee_zipcode = cardData.zip;
            this.createAccount(this.formData);
        },
        createAccount(formDataSubmitted) {
            delete this.$http.defaults.headers["authorization"];
            this.$http
                .post("mra/employee-register", formDataSubmitted)
                .then(resp => {
                    this.loading = false;
                    let ids = [];
                    let obj = {
                        id: resp.data.id
                    };
                    ids.push(obj);
                    this.showPaymentOption = false;
                    Swal.fire({
                        title: "Success!",
                        html: `Your account has been created and is now active! <a href="https://lms.train321.com/#/mra-login">Click here</a> to Login`,
                        icon: "success",
                        confirmButton: "btn btn-success",
                        confirmButtonText: "Ok"
                    }).then(result => {
                        if (result.value) {
                            this.loading = false;
                            this.$router.push("/mra-login");
                        }
                    });
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        this.loading = false
                        return Swal.fire({
                            title: "Error!",
                            text: error.response.data.message,
                            icon: "error"
                        });
                    }
                });
        }
    },
};
</script>
<style>
.form-section {
    background-color: transparent;
    border-right: 1px solid #dedede;
}

hr {
    margin-top: 2px !important;
    margin-bottom: 20px !important;
}

#selected_course li {
    font-size: 0.89em;
}

#serviceAgreement {
    float: left;
    height: 300px;
    overflow: auto;
}

#serviceAgreement p {
    font-size: 0.81rem;
    text-align: justify;
}

.el-select-group__title {
    font-size: 14px !important;
    font-weight: bolder;
    color: #28c0e7;
}

.el-select-dropdown__item {
    font-size: 13px !important;
}

.reduceFont {
    font-weight: 400 !important;
}

.price-area .row {
    margin-top: 5px;
}

.bg-gradient-primary {
    background: linear-gradient(87deg, #07c9fb 0, #ffffff 100%) !important;
}

.req {
    color: red;
}

.knowMore-info {
    font-size: 13px;
    margin-left: 6px;
    text-decoration: underline;
    font-style: italic;
    color: #ef8f1d !important;
    cursor: pointer;
}

.price-info {
    font-size: 13px;
    font-weight: bold;
}

.priceGrid {
    border-bottom: 1px solid #dedede;
    padding-top: 8px;
}

.price-info {
    display: flex;
    align-items: center;
}


.provideScroller {
    padding-top: 10px;
    overflow-y: auto !important;
    max-height: 250px !important;
}

.login-inputs {
    padding-top: 20px;
}

.ctm-btn {
    background-color: #ef8f1d;
    color: #ffffff;
    border-radius: 0 !important;
    font-size: 17px;
    margin-right: 5px;
}

.login {
    margin-right: 0px !important;
}

.mra-login .custom-form {
    background-color: #ffffff !important;
}

@media (min-width: 992px) {
    .pt-lg-9,
    .py-lg-9 {
        padding-top: 3rem !important;
    }
}

</style>
